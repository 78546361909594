import vue from "vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export const errorToast = msg => {
    vue.$toast({
        component: ToastificationContent,
        props: {
            title: msg,
            icon: "AlertTriangleIcon",
            variant: "danger",
        },
    });
};

export const warningToast = msg => {
    vue.$toast({
        component: ToastificationContent,
        props: {
            title: msg,
            icon: "BellIcon",
            variant: "warning",
        },       
    });
};

export const successToast = msg => {
    vue.$toast({
        component: ToastificationContent,
        props: {
            title: msg,
            icon: "BellIcon",
            variant: "primary",
        },       
    });
};