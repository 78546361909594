<template>
    <div
        class="
            d-flex
            content
            navbar-container
            align-items-center
            justify-content-between
        "
    >
        <!-- Nav Menu Toggler -->
        <ul v-if="!isNavMenuHidden" class="nav navbar-nav d-md-none">
            <li class="nav-item">
                <b-link class="nav-link" @click="toggleVerticalMenuActive">
                    <feather-icon icon="MenuIcon" size="21" />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <transition name="slide-fade">
            <div v-if="shouldShowCampaignInfo" class="flex-grow-1 align-items-center d-block">
                <h3 class="text-primary mb-0"> {{ campaign.name }} </h3>
            </div>
        </transition>



        <b-navbar-nav
            class="nav align-items-center ml-auto"
        >
            <campaign-list-dropdown />
            <dark-Toggler class="d-lg-block" />
<!--            <search-bar />-->
            <user-dropdown />
        </b-navbar-nav>

    </div>
</template>

<script>
import { BLink, BNavbarNav } from 'bootstrap-vue';
import useAppConfig from '@core/app-config/useAppConfig';
import { mapGetters } from "vuex";
// import SearchBar from './components/SearchBar.vue';
import DarkToggler from './components/DarkToggler.vue';
import UserDropdown from './components/UserDropdown.vue';
import CampaignListDropdown from './components/CampaignListDropdown.vue';

export default {
    components: {
        BLink,

        // Navbar Components
        BNavbarNav,
        // SearchBar,
        DarkToggler,
        CampaignListDropdown,
        UserDropdown,
    },

    props: {
        toggleVerticalMenuActive: {
            type: Function,
            default: () => {},
        },
    },

    computed: {
        ...mapGetters({
            'campaign': 'auth/campaign',
        }),

        shouldShowCampaignInfo()
        {
            if (!this.campaign)
                return false;

            return true;
        },
    },

    setup() {
        const { isNavMenuHidden } = useAppConfig();

        return {
            isNavMenuHidden,
        };
    },
};
</script>
<style>
.menu-title
{
    font-weight: 500;
}
</style>
