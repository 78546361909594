import axios from '@/apis/axios';

class Campaign
{
    static async all()
    {
        await axios.get("/sanctum/csrf-cookie");
        const response = await axios.get('/api/campaigns');
        return response.data.data;
    }

    static getOrderOfIncorporationDate()
    {
        return axios.get('/api/campaigns/incorporation-date-order');
    }

    static async create(data)
    {
        const response = await axios.post('api/campaigns', data);
        return response.data.data;
    }

    static async update(id, data)
    {
        const response = await axios.put(`api/campaigns/${id}`, data);
        return response.data.data;
    }

    static async delete(id, data)
    {
        const response =  await axios.delete(`api/campaigns/${id}`, data);
        return response;
    }

    static async switchCampaign(id)
    {
        const response =  await axios.post(`api/campaigns/switchCampaign/${id}`);
        return response;
    }
}

export default Campaign;
