<template>
  <li class="nav-item demo-inline-spacing">
<!--    <div class="demo-inline-spacing">-->

    <!-- primary -->
    <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            text="Switch Campaign"
            variant="flat-primary"
            style="margin-top: 0px;margin-right: 0px;"
    >

      <b-dropdown-item v-for="campaign in campaignList" :key="campaign.id" @click="switchCampaign(campaign)">
        {{ campaign.name }}
      </b-dropdown-item>
    </b-dropdown>

<!--  </div>-->
  </li>
</template>

<script>
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import router from '@/router/index';
import Campaign from '@/models/Campaign';
import { errorToast, successToast } from '@/views/helpers/toast';
import { SOMETHING_WENT_WRONG } from '@/constants/errors';


export default {
    components: {
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },

    computed: {
        ...mapGetters({
            'campaignList': 'auth/campaignList',
        }),

        shouldShowCampaignInfo()
        {
            if (!this.campaign)
                return false;

            return true;
        },
    },

    setup() {


        const switchCampaign = campaign => {
            if (campaign.id !== store.getters["auth/campaign"].id)
                Campaign.switchCampaign(campaign.id)
                    .then( () => {
                        store.commit('auth/SET_CAMPAIGN', campaign);
                        router.push({ path: '/' });
                        successToast("Campaign has been switch successfully.");
                    })
                    .catch(e => {
                        errorToast(SOMETHING_WENT_WRONG);
                        console.error(
                            "error while trying to switch campaign.",
                            e
                        );
                    });
        };

        return {
            switchCampaign
        };
    },



};
</script>
